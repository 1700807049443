import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd/lib';
import { equipmentsApi } from 'services/EquipmentsService';
import { userApi } from 'services/UserService';
import { EquipmentResponse, UserRoleType } from 'types';

import Spinner from 'components/Spinner';

export default function ReferenceOfMaterials() {
  const { t } = useTranslation('main-panel');
  const { isLoading, data: referenceOfMaterials } = equipmentsApi.useGetAllQuery({ pageSize: 10000 });
  const { data: user, isLoading: currentUserLoading } = userApi.useGetCurrentUserQuery();

  const columns: ColumnsType<EquipmentResponse> = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      className: 'text-primary [&&&]:p-0 h-16',
      sorter: (a, b) => (a.id ?? 0) - (b.id ?? 0),
      onHeaderCell: () => ({ className: '[&&&]:text-primary [&&&]:text-center' }),
      width: 180,
    },
    {
      title: t('Tag'),
      dataIndex: 'tag',
      key: 'tag',
      align: 'center',
      className: 'text-primary [&&&]:p-0 h-16',
      sorter: (a, b) => (a.tag && b.tag ? a.tag.localeCompare(b.tag) : 0),
      onHeaderCell: () => ({ className: '[&&&]:text-primary [&>.ant-table-column-sorters]:w-full' }),
      width: 180,
    },
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'description',
      className: 'text-primary [&&&]:p-0 h-16',
      onHeaderCell: () => ({ className: '[&&&]:text-primary [&&&]:text-center' }),
    },
  ];

  const dataSource = [...(referenceOfMaterials?.data ?? [])].sort((a, b) => (a.id ?? 0) - (b.id ?? 0));

  if (currentUserLoading || user?.role !== UserRoleType.Admin) {
    return null;
  }

  if (isLoading || !referenceOfMaterials) {
    return <Spinner />;
  }

  return (
    <div className="flex h-full max-h-full flex-col pb-5">
      <div className="mb-10 mt-3.5 flex items-center gap-2">
        <h3 className="typography-h3 font-bold text-primary">{t('Reference of materials')}</h3>
      </div>
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        className="max-h-[calc(100%-56px)] grow [&>div>div>div>div]:max-h-full [&>div>div>div>div]:overflow-auto [&>div>div>div]:flex [&>div>div>div]:max-h-full [&>div>div]:max-h-full [&>div>div]:max-w-full [&>div]:flex [&>div]:max-h-full"
        sticky
      />
    </div>
  );
}
