import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd/lib';
import ScheduledWorkCode from 'pages/ScheduledWorkOrderPage/components/ScheduledWorkCode';
import { scheduledWorkOrderApi } from 'services/ScheduledWorkOrdersService';
import { userApi } from 'services/UserService';
import { ScheduledWorkResponse, UserRoleType } from 'types';

import Spinner from 'components/Spinner';

export default function ScheduledWorks() {
  const { t } = useTranslation('planned-tasks');
  const { t: mainPanelT } = useTranslation('main-panel');
  const { isLoading, data: scheduledWorks } = scheduledWorkOrderApi.useGetScheduledWorksQuery();
  const { data: user, isLoading: currentUserLoading } = userApi.useGetCurrentUserQuery();

  const columns: ColumnsType<ScheduledWorkResponse> = [
    {
      title: t('Code'),
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      className: 'text-primary [&&&]:p-0 h-16',
      sorter: scheduledWorksCompareFunc,
      render: val => <ScheduledWorkCode code={val} />,
      onHeaderCell: () => ({ className: '[&&&]:text-primary [&>.ant-table-column-sorters]:w-full' }),
      width: 180,
    },
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'description',
      className: 'text-primary [&&&]:p-0 h-16',
      onHeaderCell: () => ({ className: '[&&&]:text-primary [&&&]:text-center' }),
    },
  ];

  if (currentUserLoading || user?.role === UserRoleType.Subcontractor) {
    return null;
  }

  if (isLoading || !scheduledWorks) {
    return <Spinner />;
  }

  const sortedScheduledWorks = [...scheduledWorks].sort(scheduledWorksCompareFunc);

  return (
    <div className="flex h-full max-h-full flex-col pb-5">
      <div className="mb-10 mt-3.5 flex items-center gap-2">
        <h3 className="typography-h3 font-bold text-primary">{mainPanelT('Reference of planned tasks')}</h3>
      </div>
      <Table
        pagination={false}
        dataSource={sortedScheduledWorks}
        columns={columns}
        className="max-h-[calc(100%-56px)] grow [&>div>div>div>div]:max-h-full [&>div>div>div>div]:overflow-auto [&>div>div>div]:flex [&>div>div>div]:max-h-full [&>div>div]:max-h-full [&>div>div]:max-w-full [&>div]:flex [&>div]:max-h-full"
        sticky
      />
    </div>
  );
}

function scheduledWorksCompareFunc(a: ScheduledWorkResponse, b: ScheduledWorkResponse) {
  const [arrA, arrB] = [a, b].map(s =>
    s.code.split('.').map(substr => (/^\d+$/.test(substr) ? Number(substr) : substr)),
  );
  for (let i = 0; i < arrA.length; i++) {
    const compareRes = compareFunc(arrA[i], arrB[i]);
    if (compareRes) {
      return compareRes;
    }
  }
  return 0;
}

function compareFunc(a?: string | number, b?: string | number) {
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  }
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }
  return 0;
}
