import React from 'react';
import { Tooltip } from 'antd';
import { scheduledWorkOrderApi } from 'services/ScheduledWorkOrdersService';

export default function ScheduledWorkCode({ code }: { code?: string }) {
  const { isLoading, data: scheduledWorks } = scheduledWorkOrderApi.useGetScheduledWorksQuery();
  const workDescription = scheduledWorks?.find(({ code: workCode }) => code === workCode)?.description;
  if (isLoading || !scheduledWorks?.length || !workDescription) {
    return <span>{code}</span>;
  }
  return (
    <Tooltip title={workDescription}>
      <span className="cursor-pointer text-primary">{code}</span>
    </Tooltip>
  );
}
